import { Injectable } from '@angular/core';
import { Trade } from '../_models/trade';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { shareReplay } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { TemplateResponse } from 'src/app/_models/trade';
import { map, finalize } from 'rxjs/operators';

const baseUrl = `${environment.apiUrl}/tamplates`;
@Injectable({
  providedIn: 'root'
})
export class TamplateService {

  constructor(private http: HttpClient) { }
  register(fordata: any): Observable<TemplateResponse> {
    return this.http.post<TemplateResponse>(`${baseUrl}/add-template`, fordata);
}
  // register(payload:any){
  //   return this.http.post(`${baseUrl}/add-template`,payload)
  // }
  addtemplatedetails(payload:any){
    return this.http.post(`${baseUrl}/add-templatedetails`,payload)
  }
  alltamplates(){
    return this.http.get(`${baseUrl}/all-templates`)
  }

  updateTemplate(tradeId:any,payload:any){
    return this.http.put(`${baseUrl}/edit-template?templateID=${tradeId}`,payload);
  }

  gettemplate(tradeID:any){
    
    return this.http.get<any[]>(`${baseUrl}/get-template?templateid=${tradeID}`);
  }
  gettemplatename(tradeID:any){
    return this.http.get<any[]>(`${baseUrl}/get-templatename?templateid=${tradeID}`);
  }
  getTemplateInfo(tradeID:any){
    return this.http.get<any[]>(`${baseUrl}/get-templates-details?templatename=${tradeID}`);
  }
  getTemplatebycustomerid(tradeID:any){
    return this.http.get<any[]>(`${baseUrl}/get-templatebycustomerid?customerid=${tradeID}`);
  }
  delete(id: string) {
    return this.http.delete(`${baseUrl}/delete-template/${id}`)
        .pipe(finalize(() => {
          return "deleted";
        }));
}

}
