<div class="newOrderCard">
    <div>
      <div class="dashboard">Dashboard </div>
      <div class="breadcum">Dashboard/ Home / New Order / DMCC </div>
    </div>
    
    <div *ngIf=" submitted=== false && !isMurabahaConfirmed && !isSaleConfirmed">
      <div class="table-header">
        <mat-icon class="material-icons-outlined add-icon">add</mat-icon>New
        Order
      </div>
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="row firstRow">
          <div class="form-group col-6">
            <label>Client 1</label>
            <div class="eachField">
                <input type="text" formControlName="clientName" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.clientName.errors }" />      
                <svg-icon name="profile" svgClass="bg-red-400 h-20 w-20"></svg-icon>
            </div>      
            <div *ngIf="submitted && f.clientName.errors" class="invalid-feedback">
                <div *ngIf="f.clientName.errors.required">Client 1 is required</div>
            </div>
        </div>
        <div class="form-group col-6">
            <label>Client 2</label>
            <div class="eachField">
              <input type="text" formControlName="clientName2" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.clientName2.errors }" />      
              <svg-icon name="profile" svgClass="bg-red-400 h-20 w-20"></svg-icon>
            </div>      
            <div *ngIf="submitted && f.clientName2.errors" class="invalid-feedback">
                <div *ngIf="f.clientName2.errors.required">Client 2 is required</div>
            </div>
        </div> 
      </div>
      <div class="row remainingRows">
        <div class="form-group col-6">
          <label>Currency</label>
          <div class="eachField1"> 
            <select formControlName="currency" class="form-control" >
              <option value="MYR">MYR</option>
              <option value="USD">USD</option>
              <option value="GBP">GBP</option>
              <option value="EUR">EUR</option>	
              <option value="JPY">JPY</option>
              <option value="HKD">HKD</option>	
              <option value="CNY">CNY</option>
              <option value="AUD">AUD</option>	
              <option value="NZD">NZD</option>
              <option value="CAD">CAD</option>	
              <option value="SGD">SGD</option>
              <option value="THB">THB</option>
              <option value="VND">VND</option>
              <option value="PHP">PHP</option>
              <option value="KRW">KRW</option>
              <option value="AED">AED</option>
              <option value="IDR">IDR</option>
              <option value="INR">INR</option>
              <option value="SDR">SDR</option>
              <option value="EGP">EGP</option>
              <option value="BND">BND</option>
              <option value="SAR">SAR</option>
              <option value="KHR">KHR</option>
              <option value="MMK">MMK</option>
              <option value="PKR">PKR</option>
              <option value="NPR">NPR</option>
              <option value="CHF">CHF</option>
              <option value="TWD">TWD</option> 
            </select>        
        </div>
        </div>
        <div class="form-group col-6">
            <label>Cost Price</label>
          <div class="eachField">
            <input type="text" formControlName="price" separator class="form-control" [ngClass]="{ 'is-invalid': submitted && f.price.errors }" />
            <mat-icon class="material-icons-outlined">paid</mat-icon>
            <!-- <svg-icon name="curre" svgClass="bg-red-400 h-20 w-20"></svg-icon> -->
          </div>  
         
          <div *ngIf="submitted && f.price.errors" class="invalid-feedback">
              <div *ngIf="f.price.errors.required">Nominal Amount is required</div>
          </div>
          
        </div>    
      </div>
      <div class="row remainingRows">
        
        <div class="form-group col-6">
            <label>Profit</label>
            <div class="eachField">
            <input type="text" formControlName="murabahavalue" separator class="form-control" [ngClass]="{ 'is-invalid': submitted && f.murabahavalue.errors }" />
            <mat-icon class="material-icons-outlined">paid</mat-icon>   
            </div>      
            <div *ngIf="submitted && f.murabahavalue.errors" class="invalid-feedback">
                <div *ngIf="f.murabahavalue.errors.required">Sale Amount is required</div>
            </div>
        </div>
        <div class="form-group col-6">
            <label>Payment Terms* </label>
            <select formControlName="tenor" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.tenor.errors }">
            <option *ngFor="let order of OtherTenor" [value]="order.value">
                {{ order.viewValue }}
            </option>
            </select>
    </div>
        <div class="form-group col-6">
            <label>Unit Of Duration</label>
              <select formControlName="tenorOtherUnit" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.tenorOtherUnit.errors }">
                <option *ngFor="let order of OtherTenorUnit" [value]="order.value">
                  {{ order.viewValue }}
                </option>
              </select>
          </div>        
      </div>
      <div class="form-group footer">
        <button class="btn btn-primary">{{enableResubmit ? 'Re-Submit' : 'Submit'}} </button>
        <a routerLink="/admin/tradeRequest" class="btn btn-link">Cancel</a>
      </div>
      
    </form>
    
    </div>
    <div *ngIf=" submitted=== true && !isMurabahaConfirmed && !isSaleConfirmed">
      <div class="table-header">
        <mat-icon class="material-icons-outlined add-icon">campaign</mat-icon>Trade Order Notification
      </div>
      <div>
        <div style="margin:10px">
          <div style="font-size: 24px;
          font-weight: 500;">Trade Order Notification DMCC</div>
          <div class="contact-label">(Certificate of Purchase - Certificate 1)</div>
        </div>
        <div style="margin:10px">Confirmation of commodity purchase transaction terms:</div>
        <div class="example-element-diagram">
          <div class="rowDetails">
             <div class="rowLeft">
               <div class="row-trade-order">
                 <div class="cell">
                   <div class="contact-label">Buyer</div>
                   <div class="label-value">SABIQ ADVISORY FZE</div>
                 </div>
                <div class="cell">
                 <div class="contact-label">Owner / As Agent Of</div>
                 <div class="label-value">{{this.form.value.clientName}}</div>
                </div>
                <div class="cell">
                 <div class="contact-label">Client 2</div>
                 <div class="label-value">{{this.form.value.clientName2}}</div>
                </div>
               </div>  
               <div class="row-trade-order">
                <div class="cell">
                  <div class="contact-label">Currency</div>
                  <div class="label-value">{{this.form.value.currency }}</div>
                </div>
               <div class="cell">
                <div class="contact-label">Price</div>
                <div class="label-value">{{this.form.value.price | currency:this.form.value.currency}}</div>
               </div>
               <div class="cell">
                <div class="contact-label">Murabaha Value</div>
                <div class="label-value">{{this.form.value.murabahavalue | currency:this.form.value.currency}}</div>
               </div>
              </div>  
              <div class="row-trade-order">          
                <div class="cell">
                  <div class="contact-label">Tenor</div>
                  <div class="label-value">{{this.form.value.tenor }}</div>
                </div>  
                <div class="cell">
                  <div class="contact-label">Tenor Unit</div>
                  <div class="label-value">{{this.form.value.tenorOtherUnit }}</div>
                </div>  
              </div>
             </div>
       
          </div>
         </div>
        
      </div>
      <div style="display: flex;align-items: center;">
        <div>
          <button class="btn btn-primary" (click)="confirmOrder()">Accept to Proceed</button>     
        </div>
        <!-- <div style="margin-left: 5px;">
          <button mat-button class="cancelBtn" (click)="rejectOrder()">Reject Order</button>
        </div> -->
        <div style="margin-left: 5px;">
          <button mat-button class="cancelBtn" (click)="cancelOrder()">Cancel</button>
        </div>
      </div>
    </div>
  
  </div>
  
  