import { NgModule, APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

// used to create fake backend
//import { fakeBackendProvider } from './_helpers';

import { AppRoutingModule } from './app-routing.module';
import { JwtInterceptor, ErrorInterceptor, appInitializer } from './_helpers';
import { AccountService } from './_services';
import { AppComponent } from './app.component';
import { AlertComponent } from './_components';
import { HomeComponent } from './home';
import { OrbitComponent } from './account/orbit/orbit.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MaterialModule} from './material.module'
;
import { AddNewOderComponent } from './home/add-new-oder/add-new-oder.component'
import { AngularSvgIconModule } from 'angular-svg-icon';
import { AngularSvgIconPreloaderModule } from 'angular-svg-icon-preloader';
import { SharedModule } from './shared/shared.module';
import { ReInitiateOrderComponent } from './home/re-initiate-order/re-initiate-order.component';
import { NewOrderAbleaceComponent } from './home/new-order-ableace/new-order-ableace.component';
import { NewOrderDmccComponent } from './home/new-order-dmcc/new-order-dmcc.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
 
@NgModule({ declarations: [
        AppComponent,
        AlertComponent,
        HomeComponent,
        OrbitComponent,
        AddNewOderComponent,
        ReInitiateOrderComponent,
        NewOrderAbleaceComponent,
        NewOrderDmccComponent
    ],
    exports: [],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ],
    bootstrap: [AppComponent], imports: [
        //  BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MaterialModule,
        CKEditorModule,
        AngularSvgIconModule.forRoot(),
        AngularSvgIconPreloaderModule.forRoot({
            configUrl: '../assets/icons.json'
        }),
        SharedModule], providers: [
        { provide: APP_INITIALIZER, useFactory: appInitializer, multi: true, deps: [AccountService] },
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {    
 }